import { graphql } from "gatsby"
import React from "react"
import Card from "../components/card"
import { Row } from "../components/grid"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"
import { getPathFromFilepath } from "../global-functions"

const Releases = ({
  data: {
    allMarkdownRemark: { edges },
  },
  location,
}) => {
  const Posts = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => (
      <Card
        key={edge.node.id}
        post={edge.node}
        title={edge.node.frontmatter.title}
        path={getPathFromFilepath(edge.node.fileAbsolutePath)}
        date={edge.node.frontmatter.date}
        image={edge.node.frontmatter.image}
      />
    ))
  return (
    <Layout location={location}>
      <SEO title="Releases" />
      <PageTitle title="Releases" />
      <Row>{Posts}</Row>
    </Layout>
  )
}

export default Releases

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/releases/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            slug
            title
            image
          }
          fileAbsolutePath
        }
      }
    }
  }
`
