import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Card = ({ title, path, date, image }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: { dir: { regex: "/src/images/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const foundImages = data.placeholderImage.edges.filter(
    item => item.node.relativePath === image
  )

  return (
    <div className="col-12 col-lg-6 mb-5">
      <div className="card border-0 text-dark w-75 mx-auto">
        {foundImages.length === 1 && (
          <Img
            className="card-img-top h-auto wp-post-image"
            fluid={foundImages[0].node.childImageSharp.fluid}
          />
        )}
        <div className="card-body text-center">
          <Link className="stretched-link text-decoration-none" to={path}>
            <h2 className="mb-1 text-primary-dark">{title}</h2>
          </Link>
          <p className="small mb-0">Single * * * {date}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
